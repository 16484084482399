const useSearchResultsOffplanSEO = (
  departmentVal,
  action,
  area,
  bedroom,
  bathVal,
  minprice,
  maxprice,
  statusVal,
  property_type,
  amenities,
  minsqftVal,
  maxsqftVal,
  newdevelopmentsVal,
  completionVal
) => {
  // departmentVal,searchtypeVal,areaVal,bedVal,minpriceVal,maxpriceVal,statusVal,buildingval,amenities,minsqftVal,maxsqftVal,newdevelopmentsVal
  const convertSlugToCapitalizedText = slug => {
    if (!slug) return ""
    return slug
      .split("-")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      .join(" ")
  }

  const getAreasText = areas => {
    let text = ""
    if (!areas) return text
    const areaValue = areas.split(",")

    if (!Array.isArray(areas)) areas = [areas]

    if(areaValue?.length>1){
      text = `${areaValue
        .map(area => convertSlugToCapitalizedText(area))
        .join(", ")
        .replace(/,([^,]*)$/, " and$1")}`
    }
    
    else if (areas.length > 0) {
      text = `${areas
        .map(area => convertSlugToCapitalizedText(area))
        .join(", ")
        .replace(/,([^,]*)$/, " and$1")}`
    }

    return text
  }

  let pagetitle = ""
  let pageh1 = ""
  let pagemetadesc = ""
  let introcopy = ""

  let myactin = "for sale"
  if (action == "lettings") myactin = "to rent"

  let myarea = getAreasText(area)

  let myproperty = "Properties"
  if (property_type) {
    myproperty =
      property_type?.charAt(0)?.toUpperCase() +
      property_type?.substring(1).replaceAll("-", " ") +
      "s"
  }
  // if(propertyTypeVal === "new_developments") {
  //     myproperty = "New homes"
  // }
  if (departmentVal === "commercial") {
    myproperty = "Commercial properties"
    if (property_type) {
      myproperty =
        "Commercial " +
        property_type.charAt(0)?.toUpperCase() +
        property_type.substring(1).replaceAll("-", " ") +
        "s"
    }
  }
  if (departmentVal === "Offplan") {
    myproperty = "Off Plan properties"
    if (property_type) {
      myproperty =
        "Off Plan " +
        property_type.charAt(0)?.toUpperCase() +
        property_type.substring(1).replaceAll("-", " ") +
        "s"
    }
  }

  let mybedroom = ""
  if (bedroom > 0) {
    if (departmentVal === "Offplan") {
      mybedroom = ` with more than ${bedroom}+ bedrooms`
    } else {
      mybedroom = ` with ${bedroom} bedrooms`
    }
  } else if (bedroom === 0) {
    mybedroom = ` with studio`
  }
  let feature = ""
  if (typeof amenities !== "string" && amenities?.length > 0) {
    // const ama =
    let text = amenities?.map((list, index) => {
      return `${list}
              ${index === amenities?.length - 1 ? "" : ""}`
    })
    feature = ` with features ${amenities
      ?.toString()
      ?.replace(/-/g, " ")
      ?.replace(/,/g, " and ")}.`
  }

  let mypricestr = ""
  if (minprice > 0 && maxprice > 0) {
    mypricestr = ` between ${currencyFormat(minprice)} and ${currencyFormat(
      maxprice
    )}`
  } else if (maxprice > 0) {
    mypricestr = ` below ${currencyFormat(maxprice)}`
  } else if (minprice > 0) {
    mypricestr = ` above ${currencyFormat(minprice)}`
  }

  let mysqftstr = ""
  if (minsqftVal > 0 && maxsqftVal > 0) {
    mysqftstr = ` between ${minsqftVal}sqft and ${maxsqftVal}sqft`
  } else if (maxsqftVal > 0) {
    mysqftstr = ` below ${maxsqftVal}sqft`
  } else if (minsqftVal > 0) {
    mysqftstr = ` above ${minsqftVal}sqft`
  }
  let completionStr = ""
  if (completionVal) {
    completionStr = ` that are ${completionVal?.replace("-"," ")}`
  }

  const currentpath =
    typeof window !== "undefined" ? window.location.pathname : ""

  if (currentpath === "/off-plan-properties/for-sale/in-dubai/") {
    pagetitle = `Search for off plan properties in Dubai | ${process.env.GATSBY_SITE_NAME}`
    pageh1 = `Off Plan Properties in Dubai for Sale`
    pagemetadesc = `Find off plan developments in Dubai, explore popular areas for investment, and access the latest property launches in the market.`
  } else if (
    (pagetitle = `${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom}${mypricestr}${feature} | ${process.env.GATSBY_SITE_NAME}`)
  )
    pageh1 =
      departmentVal === "Offplan"
        ? `${myarea} ${myproperty} ${mybedroom}${mypricestr}${feature}${completionStr}`
        : `${myproperty} ${myactin} ${
            myarea ? "in " + myarea : ""
          }${mybedroom}${mypricestr}${feature}`

  pagemetadesc = currentpath === "/off-plan-properties/for-sale/in-dubai/" ? "Find off plan developments in Dubai, explore popular areas for investment, and access the latest property launches in the market.":
   `Explore our range of for ${myproperty} ${myactin} ${
    myarea ? "in " + myarea : ""
  }. Contact haus & haus to find the right properties ${myactin} ${
    myarea ? "in " + myarea : ""
  }.`

  if (area != process.env.GATSBY_DEFAULT_AREA.toLowerCase()) {
    pagemetadesc = `Explore our range of off plan properties ${myactin} in 
    ${myarea}. Contact haus & haus to find the right properties ${myactin} in ${myarea}.`
  }
  if (property_type) {
    pagemetadesc = `Looking for an ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    } ? haus & haus are here to help you in your properties journey. `
  }
  if (bedroom > 0) {
    pagemetadesc = `Explore ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    } with more than ${mybedroom} here. Contact our expert real estate agents today to get assistance in finding the right properties ${
      myarea ? "in " + myarea : ""
    }.`
  }
  if (mypricestr) {
    pagemetadesc = `Locate the right ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mypricestr} with ${
      process.env.GATSBY_SITE_NAME
    }. Book an appointment with one of our Properties experts ${
      myarea ? "in " + myarea : ""
    }.`
  }
  if (completionVal) {
    pagemetadesc = `Identify the ${myproperty} ${myactin} that are ${completionVal}. Book a viewing with ${process.env.GATSBY_SITE_NAME} to avail expert help in knowing more about the properties that interests you.`
  }
  if (
    mypricestr &&
    (myproperty != "Properties" ||
      myproperty != "Off Plan properties" ||
      myproperty != "Commercial properties")
  ) {
    pagemetadesc = `Check out the latest collection of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mypricestr} with ${process.env.GATSBY_SITE_NAME} ${
      myarea ? "in " + myarea : ""
    }.`
  }
  if ( property_type&& completionVal) {
    pagemetadesc = `Looking for a Off Plan ${property_type} ${myactin} that are ${completionVal}? haus & haus are here to help you in your properties journey.`
  }
  if (mypricestr && bedroom > 0) {
    pagemetadesc = `Explore the list of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom}${mypricestr} with ${
      process.env.GATSBY_SITE_NAME
    } and request a viewing for the Properties that interests you.`
  }
  if (
    (myproperty != "Properties" ||
      myproperty != "Off Plan properties" ||
      myproperty != "Commercial properties") &&
    bedroom > 0
  ) {
    pagemetadesc = `Find the latest collection of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom}. Contact ${
      process.env.GATSBY_SITE_NAME
    } in ${myarea}, to arrange a viewing.`
  }
  if (mypricestr && completionVal) {
    pagemetadesc = `Check out the latest collection of ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mypricestr} that are ${completionVal} with haus & haus in ${
      myarea ? "in " + myarea : ""
    }.`
  }
  if (
    (myproperty != "Properties" ||
      myproperty != "Off Plan properties" ||
      myproperty != "Commercial properties") &&
    bedroom > 0 &&
    mypricestr
  ) {
    pagemetadesc = `Explore ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom}${mypricestr} available with ${
      process.env.GATSBY_SITE_NAME
    }. Contact one of our real estate agents for assistance in finding your properties ${
      myarea ? "in " + myarea : ""
    }.`
  }

  if (
    (myproperty != "Properties" ||
      myproperty != "Off Plan properties" ||
      myproperty != "Commercial properties") &&
    bedroom > 0 &&
    mypricestr &&
    completionVal
  ) {
    pagemetadesc = `Explore ${myproperty} ${myactin} ${
      myarea ? "in " + myarea : ""
    }${mybedroom} with that are ${completionVal} ${mypricestr} available with ${
      process.env.GATSBY_SITE_NAME
    }. Contact one of our real estate agents for assistance in finding your properties in ${
      myarea ? "in " + myarea : ""
    }.`
  }

  introcopy = `Explore our ${pageh1.replace(
    "Properties",
    "Properties"
  )}. For more information about Properties ${myactin} ${
    myarea ? "in " + myarea : ""
  }, please <a href='/contact/'>get in touch</a> with ${
    process.env.GATSBY_SITE_NAME
  }.`

  return {
    pagetitle,
    pageh1,
    pagemetadesc,
    introcopy,
  }
}

export default useSearchResultsOffplanSEO

const currencyFormat = (num, currency = "AED ", withSpace = false) => {
  var filterNum = filterNumber(num)
  if (!filterNum) {
    return `${currency} 0`
  }
  if (withSpace) {
    return `${currency} ${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  } else {
    return `${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  }
}

const filterNumber = str => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

const numberFormat = num => {
  if (!num) return 0
  return new Intl.NumberFormat("en-US", {}).format(num)
}
